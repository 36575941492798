import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'

import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'
import { useGetPatrimony } from 'services/patrimony/hooks/useGetPatrimony'
import { useGetPatrimonyFragment, useToast } from 'shared/hooks/index'
import { updatePatrimonySchema } from 'domains/customer/schemas'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { joiResolver } from '@hookform/resolvers/joi'
import { AxiosError } from 'axios'
import {
  PatrimonyFragment,
  PatrimonyWithAccountRequest,
} from 'services/patrimony/types'

import { PatrimonyForm } from 'domains/customer/components/PatrimonyForm/PatrimonyForm'
import { TypeError } from 'domains/customer/components/PatrimonyForm/components'
import { usePutPatrimony } from 'services/patrimony/hooks/usePutPatrimony'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { Button, Loader } from 'components'

import styles from './Patrimonies.module.scss'
import { paths } from 'routes'

const Patrimony = () => {
  const { patrimony: patrimonyContext, customer } = useCustomerContext()
  const [errorType, setErrorType] = useState<TypeError>()
  const [patrimonyFragmentName, setPatrimonyFragmentName] = useState('')

  const {
    data: patrimonyFragment,
    isError: isErrorPatrimonyFragment,
    isFetching: isFetchingPatrimonyFragment,
    fetchNextPage: fetchNextPatrimonyPage,
  } = useGetPatrimonyFragment(customer?.id || '', patrimonyFragmentName)

  const form = useForm<PatrimonyWithAccountRequest>({
    resolver: joiResolver(updatePatrimonySchema),
    mode: 'onChange',
  })

  const navigate = useNavigate()
  const location = useLocation()
  const { addToast } = useToast()

  const { mutate: mutateUpdatePatrimony } = usePutPatrimony()
  const { reset, setValue, register, handleSubmit } = form

  const [selectedPatrimonyId, setSelectedPatrimonyId] = useState('')

  const {
    refetch,
    isLoading,
    data: patrimony,
  } = useGetPatrimony(selectedPatrimonyId)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (patrimonyContext?.id) {
      setSelectedPatrimonyId(patrimonyContext.id)
    }
  }, [patrimonyContext?.id])

  const handleUpdatePatrimony = useCallback(
    (payload: PatrimonyWithAccountRequest) => {
      const formattedPayload = {
        ...payload,
        patrimony: {
          ...payload.patrimony,
          postalCode: String(payload.patrimony.postalCode).replace(/\D/g, ''),
          customerId: customer?.id,
        },
      }

      mutateUpdatePatrimony(formattedPayload, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['patrimony-fragment', payload.customerId],
          })
          setSelectedPatrimonyId(payload.patrimony.id)

          addToast({ message: 'Patrimônio editado com sucesso.' })
        },
        onError: (error: AxiosError) => {
          const messageError = error.response?.data.message as string

          const messageErrors: Record<string, TypeError> = {
            'A patrimony with the same name already exists': 'NAME',
            'A patrimony with the same address already exists': 'ADDRESS',
            'Patrimony with the same name and address already exists': 'ALL',
          }

          setErrorType(messageErrors[messageError])

          addToast({
            type: 'alert',
            message: 'Erro ao editar patrimônio. Tente novamente.',
          })
        },
      })
    },
    [addToast, mutateUpdatePatrimony, queryClient, refetch, reset],
  )

  useEffect(() => {
    if (patrimony) {
      register('hasAccount')
      register('patrimony.id')
      register('patrimony.imageId')
      register('patrimony.cityName')
      register('patrimony.stateName')
      register('patrimony.districtName')

      reset({
        hasAccount: true,
        patrimony: {
          id: patrimony.id,
          note: patrimony.note,
          name: patrimony.name,
          ...(patrimony.phone?.phoneNumber && {
            phone: {
              countryCode: patrimony.phone?.countryCode,
              provinceCode: String(patrimony.phone?.provinceCode),
              phoneNumber: String(patrimony.phone?.phoneNumber),
            },
          }),
          responsible: patrimony.responsible,
          number: patrimony.number,
          state: patrimony.stateId,
          address: patrimony.address,
          adjunct: patrimony.adjunct,
          city: patrimony.cityId,
          cityName: patrimony.cityName,
          reference: patrimony.reference,
          stateName: patrimony.stateName,
          districtId: patrimony.districtId,
          districtName: patrimony.districtName,
          postalCode: String(patrimony.postalCode),
          imageId: patrimony.patrimonyImageId,
        },
      })
    }
  }, [patrimony, register, setValue])

  return (
    <>
      <Loader isVisible={isLoading} />
      <div className={styles.patrimonyWrapper}>
        <div className={styles.patrimonySelector}>
          <Combobox
            label={{
              text: 'Patrimônio',
            }}
            value={patrimony?.name}
            isError={isErrorPatrimonyFragment}
            onEndReached={fetchNextPatrimonyPage}
            onSearch={(search) => setPatrimonyFragmentName(search)}
            isLoading={isFetchingPatrimonyFragment}
            onChange={(selected) => {
              const fragment =
                selected as unknown as ComboboxItem<PatrimonyFragment>
              setSelectedPatrimonyId(fragment.value.id)
              reset()
            }}
            items={parseDataToComboboxV2(patrimonyFragment || [], 'name')}
          />
          <Button
            width="179px"
            icon={PlusIcon}
            type="secondary"
            buttonTitle="Novo patrimônio"
            onClick={() => {
              setSelectedPatrimonyId('')
              reset({
                patrimony: undefined,
              })
            }}
          />
        </div>
        <FormProvider {...form}>
          <div className={styles.patrimonySection}>
            <PatrimonyForm
              onUndo={() => {
                refetch()
                reset()
              }}
              errorType={errorType}
              title="Informações gerais"
              onSave={handleSubmit(handleUpdatePatrimony)}
              onGoBack={() =>
                navigate(paths.account.list, { state: location.state })
              }
            />
          </div>
        </FormProvider>
      </div>
    </>
  )
}

export default Patrimony
